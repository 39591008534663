table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
tr > th {
  background-color: rgb(194, 194, 194);
}
td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}
input,
input::placeholder {
  font-size: 1em;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}

@layer base {
  html {
    font-family: Open Sans, system-ui, sans-serif;
  }
}

@layer components {
  .revision-btn {
    @apply max-w-fit h-[45px] flex items-center px-4 text-[#FFC658] border border-[#FFC658] bg-[#FFC658]/10 text-[18px] font-semibold   rounded-[10px];
  }
  .progress-btn {
    @apply max-w-fit h-[45px] flex items-center px-4 text-[#44B741] border border-[#44B741] bg-[#44B741]/10 text-[18px] font-semibold   rounded-[10px];
  }
  .rendition-btn {
    @apply max-w-fit h-[45px] flex items-center px-4 text-[#EA751D] border border-[#EA751D] bg-[#EA751D]/10 text-[18px] font-semibold   rounded-[10px];
  }
  .close-btn {
    @apply max-w-fit h-[45px] flex items-center px-4 text-[#7677F4] border border-[#7677F4] bg-[#7677F4]/10 text-[18px] font-semibold   rounded-[10px];
  }
  .primary-btn-extend {
    @apply !h-[55px] block w-[100%] text-[#FFFFFF] bg-[#7677F4] text-[18px] font-semibold px-4 rounded-[10px];
  }
  .primary-btn {
    @apply !h-[55px] text-[#FFFFFF] bg-[#7677F4] text-[16px] font-semibold px-4 rounded-[10px];
  }
  .secondary-btn-bg {
    @apply !h-[55px]  text-[#7677F4]  bg-[#7677F4]/10 text-[16px] font-semibold px-4 rounded-[10px];
  }
  .secondary-btn {
    @apply !h-[55px] text-[#7677F4] border border-[#7677F4] bg-[white] text-[16px] font-semibold px-4 rounded-[10px];
  }
  .grey-btn {
    @apply !h-[55px] text-[#898989]  bg-[#F8F8F8] text-[16px] font-semibold px-4 rounded-[10px];
  }
  .disabled-btn {
    @apply !h-[55px] text-[#D7D7D7]  bg-[#F8F8F8] text-[16px] font-semibold px-4 rounded-[10px];
  }
  .white-btn {
    @apply !h-[55px] text-[#898989]  bg-[#FFFFFF] text-[16px] font-semibold px-4 rounded-[10px];
  }
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* .MuiDataGrid-colCellTitle {
  display: block;
  text-align: center;
  width: 100%;
}

.MuiDataGrid-cell {
  display: block;
  position: relative;
  text-align: center;
} */
.MuiDataGrid-cellCenter {
  justify-content: center;
  background-color: aqua;
}

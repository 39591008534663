table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}
.thead_th {
  background-color: rgb(194, 194, 194);
  width: 300px !important;
  min-width: 300px;
}
/* td, */
#tbody_td {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  min-width: 300px;
  width: 300px !important;
}
input,
input::placeholder {
  font-size: 0.8em;
}

.step-item {
    @apply relative flex flex-col justify-center items-center w-36;
    /* display: flex;
    justify-content: center;
    justify-items: center;
    width: 36; */
  }
  .step-item:not(:first-child):before {
    background-image: linear-gradient(to right,#D7D7D7 35%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 20px 2px;
    background-repeat: repeat-x;
    @apply content-['']  absolute w-full  h-[3px] mt-[6px] right-1/4 top-1/3 -translate-y-2/4;
  }
  .step {
    @apply w-10 h-10 flex items-center justify-center z-10 relative bg-white rounded-full border border-[#D7D7D7] font-semibold text-[#D7D7D7];
  }
  .active .step {
    @apply border border-[#7677F4] text-[#7677F4];
  }
  .complete .step {
    @apply bg-[#D7D7D7];
  }
  .complete .p {
    @apply text-[#FFFFFF] ;

  }
  /* .complete:not(:first-child):before,
  .active:not(:first-child):before {
    @apply bg-green-600;
  } */

  .Steps{
    display: flex;
    justify-content: center;
  }


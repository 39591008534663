.scrollbar::-webkit-scrollbar {
  width: 12px;
}
.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  /* background: #F7F4ED; */
}
.scrollbar::-webkit-scrollbar-thumb {
  background: #d7d7d7;
  border-radius: 100vh;
  border: 3px solid #f6f7ed;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #c0a0b9;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
